<template>
  <div 
    class="navbar navbar-expand-xl fixed-top bg-primary"
    :class="{ 'onScroll': !view.topOfPage}"
  >
    <div class="container-xl">        
          <router-link class="navbar-brand me-0" :to="{name: 'Home'}" v-scroll-to="{ el: '#hero', offset: -80 }">
            <div class="d-flex align-items-center">
              <img src="@/assets/imgs/logo.svg" alt="Auchan" class="logo" />
            </div>
          </router-link>        
          <button 
              class="navbar-toggler order-3 order-xl-1" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target="#navbarSupportedContent" 
              aria-controls="navbarSupportedContent" 
              aria-expanded="false" 
              aria-label="Toggle navigation"
              @click="changeState" 
              target="nav-collapse"
          >
          <div
              class="custom-hamburger"
              :class="{'custom-hamburger-close' : isOpen}"
              @click="view.topOfPage = false"
          >
              <span></span>
              <span></span>
              <span></span>
          </div>

          </button>
          <div 
              class="collapse navbar-collapse " 
              id="navbarSupportedContent"
              ref="collapsebox"
          >
            <ul class="navbar-nav w-100 justify-content-end">
                <li class="nav-item"
                    @click="isOpen=false;setGTM(item.name)"
                    v-for="item in items" :key="'item' + item.id" 
                >
                    <div 
                        v-if="menuVisible(item.logIn, item.logOut)"
                        class="nav-link active ps-md-1 ps-lg-1 ps-xl-2 pe-xl-2" 
                        aria-current="page"
                        @click="changeState" 
                    >
                      <a v-if="item.id == 4"
                        :href="$store.state.file_url"
                        target="_blank"
                        class="text-white fw-700 text-uppercase"
                      >
                        {{ item.name }}
                      </a>
                      <a v-else 
                        class="text-white fw-700 text-uppercase"
                        v-scroll-to="{ el: item.link, offset: -80 }"    
                        @click="goToHomePage(item.link)"               
                      >
                        {{ item.name }}
                      </a>
                    </div>
                </li>           
            </ul>
            <div 
              v-if="this.$store.state.user"
              @click="changeState;logout();setGTM('Kijelentkezés')"
              class="d-flex justify-content-center"
            >
              <!-- <button class="btn btn-secondary ms-0 ms-xl-3 ms-xxl-5">
                Kijelentkezés
              </button> -->
              <span class="fw-700 mx-4 btn btn--log-out">Kijelentkezés</span>
            </div>    
          <img src="@/assets/imgs/logo-purina.svg" alt="Auchan" class="logo mx-auto d-block" />
          </div>
      </div>    
  </div>

</template>

<script>
export default {
  data() {
    return {
        isOpen: false,
        view: {
            topOfPage: true
        },
        items: [
        {
          'id': 1,
          'name': 'Regisztráció',
          'link': '#regisztracio',
          'logIn': false,
          'logOut': true,
        },
        {
          'id': 2,
          'name': 'Belépés',
          'link': '#belepes',
          'logIn': false,
          'logOut': true,
        },
        {
          'id': 3,
          'name': 'Beküldés',
          'link': '#palyazat',
          'logIn': true,
          'logOut': false,
        },
        {
          'id': 4,
          'name': 'Játékszabály',
          'link': '#jatekszabaly',
          'logIn': true,
          'logOut': true,
        },
        {
          'id': 5,
          'name': 'Nyertesek',
          'link': '#nyertesek',
          'logIn': true,
          'logOut': true,
        },
        {
          'id': 6,
          'name': 'Részt vevő termékek',
          'link': '#termekek',
          'logIn': true,
          'logOut': true,
        },
        {
          'id': 7,
          'name': 'Kapcsolat',
          'link': '#kapcsolat',
          'logIn': true,
          'logOut': true,
        }
      ]
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  //   created(){
  //   if ( this.$store.state.promoStatus == 'after' ){
  //       let menuItems = this.items.map((x) => x)        
  //       menuItems.shift()
  //       this.items = menuItems
  //   }
  // },
  methods: {
    handleScroll(){
      if(window.pageYOffset>0){
        if(this.view.topOfPage) this.view.topOfPage = false
      } else {
        if(!this.view.topOfPage) this.view.topOfPage = true
      }
    },
    changeState(){
      this.isOpen=!this.isOpen
      if(!this.isOpen){
        setTimeout(() => {
          this.$refs.collapsebox.classList.remove('show')  
        }, 400)
      }
    },
    menuVisible(logIn, logOut){
      let state
      if(this.$store.state.user){
        logIn ? state = true : state = false
        return state 
      }
      else {
        logOut ? state = true : state = false
        return state
      }
    },
    goToHomePage(itemLink){
      
      if (this.$route.name != 'Home'){
        this.$router.push({name: 'Home'})

        setTimeout(() => {
                var element = document.getElementById(itemLink.substring(1))
                element.scrollIntoView()       
        }, 1000);
      }
    },
    setGTM(item){
      this.GTtrackGA4({
        'event' : 'event',
        'category': 'fejlec',
        'action': 'kattintas',
        'label': item,
        'menu': 'fejlec',
        'clicked_text':item,
        'action_type': 'menu_kattintas'
      })
    },
  }
  
  
}
</script>