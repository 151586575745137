<template>
    <div id="adataim" class="my-data pt-0">
        <div class="container-xl">
            
            <div class="row">
                <div class="col-12">
                    <h2 class="font-header text-primary fw-800 text-uppercase text-center mb-3 mb-md-5 mt-md-5">
                        Kezelt adataim 
                    </h2>
                </div>
            </div>

            <div class="row">
                <div class="col-1 d-none d-xxl-block"></div>
                <div 
                    class="col-12 col-xxl-10"
                >
                    <div class="row ">
                        <div class="col-12">
                            <!-- KEZELT ADATAiM -->
                            <div class="form-inner-container-small form-inner-container-shadow mb-4 bg-white">
                                <button 
                                    class="btn btn-collapse collapsed fw-700 text-black fs-20"
                                    type="button" 
                                    data-bs-toggle="collapse" 
                                    data-bs-target="#collapse1" 
                                    aria-expanded="true" 
                                    aria-controls="collapseExample">
                                        Kezelt adataim
                                </button>
                                <!-- COLLAPSE 1 CONTENT -->
                                <div class="collapse" id="collapse1">
                                    <div class="">
                                        <div class="my-4 font-secondary text-black">
                                            <p>
                                            Amennyiben élni kívánsz a személyes adataid kezelését érintő -az Info Tv. és a GDPR adta- lehetőségekkel az alábbi módon kérheted adataid törlését, visszavonását, az adatkezelés korlátozását, valamint élhetsz adathordozási-, és tiltakozási jogoddal az adatkezelőnél. Ha töröltetni vagy módosítani kívánod az adataid az adatbázisunkban, ezt korlátozás és indokolás nélkül, valamint ingyenesen kérheted:</p>
                                            <p>
                                            -a Játék ideje alatt az  <a :href="'mailto:'+$api_conf.emailAddress" class="text-primary fw-bold text-decoration-underline">{{  $api_conf.emailAddress }}</a> e-mail címen, adattörlés tárggyal vagy a Weboldalon található saját felhasználói fiókban a Kérelem/Törlés pontban. A Játékos tudomásul veszi, hogy adatainak a Játék időtartama alatti törlése a Játékból való kizárását eredményezi, mivel Szervező az adatok hiányában nem tudja biztosítani a Játékos részvételét.
                                            <br/><br>

                                            -a fődíjak sorsolását (2024. augusztus 27.) követően adatkezeléssel és hírlevél küldéssel szemben tiltakozhatsz                                          
                                           <ul class="ms-0 mt-3">
                                                <li>
                                                az Auchan Magyarország Kft. (2040 Budaörs Sport u. 2-4, Marketing osztály) postacímére küldött nyilatkozattal, a borítékon az "Auchan Purina - adatvédelmi kérelem" megjelöléssel,</li>
                                                <li>vagy az Auchan áruházak vevőszolgálatain,</li>
                                                <li>vagy az Auchan Info Centerében (email: info@auchan.hu vagy tel.: 06 80 10 90 10)</li>
                                                <li>vagy az Auchan fiókba történő belépést követően a hírlevél küldés témájában</li>
                                            </ul>
                                            </p>
                                        </div>
                                        <div class="row">
                                            <div 
                                                v-for="(input, idx) in inputs"
                                                :key="idx"
                                                class="col-12 col-md-6"
                                            >
                                                <CustomInput
                                                    v-if="isInputShow(input)"
                                                    :label="input.label"
                                                    name=""
                                                    v-model="input.content"
                                                    disabled
                                                />
                                            </div>  
                                        </div>
                                    </div><!-- end of COLLAPSE 1 CONTENT -->
                                </div><!-- end of COLLAPSE 1 -->
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        
                        <!-- ADATOK TÖRLÉSE -->
                        <div class="col-12">
                            <div class="form-inner-container-small form-inner-container-shadow mb-4 bg-white">
                                <button 
                                class="btn btn-collapse collapsed fw-700 text-black fs-20"  
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#collapse2" 
                                aria-expanded="true" 
                                aria-controls="collapseExample">
                                    Adatok exportálása/törlése
                                </button>
                                <!-- COLLAPSE 2 CONTENT -->
                                <div class="collapse" id="collapse2">
                                    <div class="col-12">
                                        <!-- FORM -->
                                        <ValidationObserver 
                                            class="mb-5"
                                            ref="observer" 
                                            v-slot="{ handleSubmit }"
                                        >
                                            <form 
                                                class="mt-4"
                                                @submit.prevent="handleSubmit(onSubmit)"
                                            >
                                               <div class="col-12">
                                                    <DropDown
                                                        label="Adatmódosítás"
                                                        name="topic"
                                                        placeholder="Válassz"
                                                        :options="options"
                                                        @selectedItem="form.type = $event"
                                                        rules="required"
                                                    />
                                                </div>                                                
                                                  <div class="row">
                                                    <div 
                                                        v-for="(input, idx) in inputs"
                                                        :key="idx"
                                                        class="col-12 col-md-6"
                                                    >                                                        
                                                         <CustomInput
                                                                v-if="isInputShow(input)"
                                                                :label="input.label"
                                                                name=""
                                                                v-model="input.content"
                                                                disabled
                                                            />
                                                    </div>  
                                                </div>

                                                <div class="col-12">
                                                    <div class="col-12 d-flex justify-content-start pb-3 pb-xl-0">
                                                        <input 
                                                            class="btn btn-secondary btn-submit" 
                                                            type="submit" 
                                                            value="Küldés"
                                                        >
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <!-- form visszajelzés -->
                                                    <div class="alert alert-success" v-if="formSendStatus == 'success'">
                                                        Sikeres üzenetküldés
                                                    </div>
                                                    <div class="alert alert-danger" v-if="formSendStatus == 'not_success'">
                                                        Sikertelen üzenetküldés
                                                    </div>
                                                </div>                                                
                                            </form>
                                        </ValidationObserver> <!-- end of FORM -->

                                    </div><!-- end of COLLAPSE 2 CONTENT -->
                                </div><!-- end of COLLAPSE 2 -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import CustomInput from '@/components/base/CustomInput.vue'
import DropDown from '@/components/base/DropDown.vue'
export default {
    components: {
        ValidationObserver,
        CustomInput,
        DropDown
    },   
    data () {
        return {           
            options: [ 'Adataim törlése','Adataim exportálása/hordozása'],
            formSendStatus: null,           
            inputs: [
                {
                    id: 1,
                    label: 'Név',
                    content: this.$store.state.user.last_name + ' ' + this.$store.state.user.first_name
                },
                {
                    id: 2,
                    label: 'E-mail cím',
                    content:  this.$store.state.user.email
                },
                {
                    id: 3,
                    label: 'Telefonszám',
                    content:  this.$store.state.user.phone
                },
               /* {
                    id: 4,
                    label: 'Kedvenc Auchan áruház',
                    content: this.$store.state.user.favourite_shop
                },*/
                {
                    id: 5,
                    label: 'Irányítószám',
                    content: this.$store.state.user.zip
                },
                {
                    id: 6,
                    label: 'Város',
                    content: this.$store.state.user.city
                },
                {
                    id: 6,
                    label: 'Utca',
                    content: this.$store.state.user.street
                },
                {
                    id: 7,
                    label: 'Házszám',
                    content: this.$store.state.user.house
                }
            ],
            form: {
                type: '',
                name: this.$store.state.user.last_name + ' ' + this.$store.state.user.first_name,
                email: this.$store.state.user.email,
                phone: this.$store.state.user.phone,
                favourite_shop: this.$store.state.user.favourite_shop
            }    
        }
    },       
    methods: {
        isInputShow(input){
            if (parseInt(input.id) > 4){
                if (input.content){
                    return true
                } else {
                    return false
                }                
            }      
            return true      
        },
        removeMsg(){
            setTimeout(() => {
                this.formSendStatus = null                

            }, 5000);
        },        
        onSubmit(){
            let _this = this

            this.post('profile/userrequest',{
                topic: _this.form.type,
                request: _this.form.type
            }).then((resp)=>{
                if (resp.data.status == true)
                {
                    this.formSendStatus = 'success'
                
                }
                this.removeMsg()
            }).catch(()=>{
                this.formSendStatus = 'not_success'
                this.removeMsg()
            })
        }
    }
}
</script>