<template>
    <div id="termekek" class="products block--big position-relative">
        <div class="container-xl">                    
            <div class="row">                
                <div class="col-md-12 d-flex flex-column text-center">
                    <h2 class="font-header text-primary text-center mb-7">
                        Részt vevő termékek
                    </h2>
                </div>
                <div class="col-12 col-lg-8 text-center offset-lg-2 mb-5">
                    <div class="mx-auto text-black">
                        Magyarországon valamennyi Auchan áruházban vagy az Auchan Online Áruházában<br>(<a class="text-primary fw-bold" href="https://auchan.hu/shop" target="_blank">https://auchan.hu/shop</a>) vásárolt a Játékban részt vevő <b>FELIX, GOURMET vagy FRISKIES macskaeledel termékek.</b> A választék áruházanként eltérő lehet.
                    </div>
                </div>
                <div class="col-12 flex-row d-flex flex-wrap text-center align-items-center justify-content-center my-5 products-imgs">
                    <img src="imgs/products/purina-felix-logo.svg" class="img-fluid" />
                    <img src="imgs/products/purina-g-logo.svg" class="img-fluid mx-5" />
                    <img src="imgs/products/purina-friskies-logo.svg" class="img-fluid" />
                </div> 
                <div class="col-12 text-center my-5">                    
                    <a class="btn btn-primary" @click="setGtm('Termékek listája')" :href="$api_conf.apiDomain+'/pdf/resztvevo_termekek.pdf?'" target="_blank">
                        Részt vevő termékek listája
                    </a>
                </div>
                             
                <div class="col-12">
                    <div class="d-flex flex-column">
                        <p class="text-center text-primary fs-14" >A képek csak illusztrációk.</p>
                    </div>
                </div>  
            </div>         
        </div>
    </div>
</template>

<script>

export default {
    data() {
      return {
        settings: {
            arrows: true,
            dots: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        },
      }
    },
    methods: {   
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'termekek',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'button': 'termekek'
            })
        }
    }
}
</script>