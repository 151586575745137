<template>
    <div id="nyertesek" class="winners block--big bg-primary">
        
            <h2 class="text-center mt-0 text-primary mb-5">
                Nyertesek
            </h2>
            <div class="container-xl">
                <div class="row">                
                    <div class="col-12 col-xl-8 offset-xl-2 mb-0 mb-md-5 text-center order-1">
                        <p class="m-0 winners__text text-black">                        
                            <b>Kedves Játékosunk!</b><br><br>
                            A napi nyeremények* nyertesei az adott napot követő első munkanapon (pénteki/szombati/vasárnapi nyeremények esetében a következő hétfőn, ünnepnap esetén a következő munkanapon) kerülnek kiértesítésre a Lebonyolító által küldött e-mail üzenetben.<br><br>
                            *A nyerőidőpontok 8 és 22 óra között kerülnek kisorsolásra.
                            <br><br><b>Sorsolás:</b><br>
                            A fődíjak sorsolására <b>2024. augusztus 27-én 14:00 órakor</b> kerül sor. A sorsolás helyszíne: A4C Marketing Kft. székhelye (1092 Budapest, Bakáts tér   2., bejárat a Bakáts utca 5-7. felől)
                             
                        </p>
                       <!-- <div class="text-white mt-5 custom-winning-box">
                            <span class="fw-bold text-secondary h5 d-block">Sorsolás</span><br>
                            <p>A fődíj, valamint extra nyeremény sorsolására <span class="text-secondary">2024. 07. 22-én 14:00 órakor</span> kerül sor.</p>
                            <p class="fw-bold">A sorsolás helyszíne: A4C Marketing Kft. székhelye (1092 Budapest, Bakáts tér 2., bejárat a Bakáts utca 5-7. felől)</p>
                        </div>-->
                    </div>                                                
                </div>
            </div>
            <div class="winners__winning-wrap">
                <div class="container-xl py-0">
                    <div class="row">
                        <div class="col-12 col-lg-4 text-center">
                            <img src="@/assets/imgs/winnings-fodij.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-lg-4 text-center my-5 my-lg-0">
                            <img src="@/assets/imgs/winnings-heti.png" class="img-fluid">
                        </div>
                        <div class="col-12 col-lg-4 text-center">
                            <img src="@/assets/imgs/winnings-napi.png" class="img-fluid">
                        </div>
                        <div class="col-12">
                            <p class="text-center text-primary fs-14 my-lg-5 py-5">A nyeremények képe csak illusztráció!</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- NYertesek -->
            <div class="container-xl block--big py-5 mt-5">
                <div class="row">     
                    <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                        <!-- TAB -->
                        <div class="winners-button-select mb-5 p-1" >
                            <button 
                                class="btn text-primary fw-700 " 
                                :style="{'background: rgba(255, 255, 255, 0.6)':winnerState === 'napi'}" 
                                v-on:click="setWinnerState('napi')"
                            >
                                Napi <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button>
                         <!--   <button 
                                class="btn text-primary fw-700 " 
                                :style="{'background: rgba(255, 255, 255, 0.6)':winnerState === 'heti'}" 
                                v-on:click="setWinnerState('heti')"
                            >
                                Heti <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button> -->
                            <button 
                                class="btn fw-700 text-primary"
                                :style="{'background: rgba(255, 255, 255, 0.6)':winnerState === 'fodij'}" 
                                v-on:click="setWinnerState('fodij')"
                            >
                                Fődíj <span class="d-none d-md-block ms-1">nyertesek</span>
                            </button>
                        </div>
                        <!-- TABLE -->
                        <div class="winners__border mb-5">
                            <div class="winners__table-wrap winners__table-wrap--heading">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  >
                                    <thead>
                                        <tr class="winners-top font-primary bg-secondary" >
                                            <th class="w-50 text-primary">Nyertes neve</th>
                                            <th class="w-50 text-primary">Város</th>                          
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="winners__table-wrap winners__table-wrap--bg text-dark">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  :class="[`winner-table_${winnerState}`]" >
                                    <tbody v-if="filteredWinners && filteredWinners.length && loginShow">                          
                                        <tr class="winners-list fs-14 text-white" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                                            <td class="winners-id fw-700 text-primary w-50 p-3">
                                                <div>{{ winner.name }}</div>                                     
                                            </td>                          
                                            <td class="winners-product text-primary text-right text-md-left w-50 p-3">
                                                <span>{{winner.winning_time}}</span>
                                            </td>
                                        </tr>                            
                                    </tbody>
                                    <tbody v-else>
                                        <tr v-if="loginShow">
                                            <td v-if="winnerState != 'fodij'" class="no-winner text-black text-center py-3" colspan="2">
                                                Hamarosan kiderül, kik lettek a nyertesek
                                            </td>
                                            <td v-if="winnerState == 'fodij'" class="no-winner text-black text-center py-3" colspan="2">
                                                Hamarosan kiderül, kik lettek a fődíj nyertesei
                                            </td>                                      
                                        </tr>
                                        <tr v-else>
                                            <td class="no-winner text-center py-3" colspan="2">
                                                A kisorsolt nyertesek részére a nyeremények átadása megtörtént!
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>                        
                            </div>

                            
                        </div>

                        <div class="d-flex justify-content-center py-4" v-if="needMoreBtn">
                                <button target="_blank" type="button" class="btn btn-primary" v-on:click="moreWinners(true)">Mutasd a további nyerteseket</button>
                        </div>
                    </div>           
                    
                </div> 
            </div> 
            
        
    </div>
</template>


<script>
export default {
    data () {
        return {
            winnersAll:[],
            winnerState:'napi',
            needMoreBtn: false,
            needMoreWinners: false
        }
    },
    computed:{
        filteredWinners() {
            let result = []
            let _this = this
        
            if(this.winnersAll ?? []){                
                
               // result = this.winnersAll.filter(w => w.type === this.winnerState)

                _this.winnersAll.forEach(w => {
                    console.log("w elemek",w);
                    if (_this.winnerState == 'napi'){
                        if (w.type != 'fodij'){
                            result.push(w)
                        }
                    } else {
                        if (_this.winnerState == 'fodij'){  
                            if (w.type == 'fodij'){
                                result.push(w)
                            }
                        }
                    }
                   

                })

                console.log("result:",result);

                if(result.length > 10 && !this.needMoreWinners){
                    this.checkBtn(true);
                    return result.slice(0,10);
                }else{
                    this.checkBtn(false);
                    return result;
                }
            }
            this.checkBtn(false);
            return result;
        }
    },
    methods: {
        setWinnerState(newState) {
            this.moreWinners(false); 
            this.winnerState = newState;      
        },
        checkBtn(newState) {
            this.needMoreBtn = newState;
        },
        moreWinners(newState){
            this.needMoreWinners = newState;
        },
        selectWinners(){
          //  let _this = this
           // this.wins.forEach( item => {                
           //     let winTypeWinners = _this.winnersAll.filter( element => element.type == item.type )
                //console.log('winTypeWinners',winTypeWinners)
           //     if (winTypeWinners)
          //          item.winners = winTypeWinners
          //  })
        }
    },
    created(){
        this.get('info/winners',{})
        .then((resp) => {
          this.winnersAll = resp.data.winners
         // this.winnerState = "napi";
          //this.selectWinners()
        }).catch((err) => {
            console.log(err)
        })
        this.setWinnerState('napi')
    }
    
}
</script>