<template>    
    <div class="hero__outer">
        <div class="hero hero__inner" id="hero">        
            <div class="hero-container">
                <!-- CONTENT -->
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-lg-7 col-xl-5 position-relative px-0">
                            
                            <div class="hero-product d-none d-lg-block"></div>
                            <img src="@/assets/imgs/hero-bg.jpg" class="img-fluid d-block d-lg-none"/>
                        </div>
                        <div class="col-5 d-lg-flex d-none d-xl-none align-items-center">
                            <h1 class="text-uppercase mt-5 text-primary pt-lg-5 text-center">Indulj a napi nyereményekért<br>és a fődíjért!</h1>
                        </div>
                        <div class="bg-white py-3 d-block d-lg-none">
                            <div class="d-flex justify-content-around align-items-center hero-logo">
                            
                                <img src="imgs/products/purina-felix-logo.svg" class="img-fluid" />
                                <img src="imgs/products/purina-g-logo.svg" class="img-fluid" />
                                <img src="imgs/products/purina-friskies-logo.svg" class="img-fluid" />
                            </div>
                        </div>  
                        <div class="col-12 col-xl-7 d-flex flex-wrap justify-content-center align-items-start">
                            <div class="row">
                                <div class="col-12 mb-5 pb-5 d-none d-xl-block">
                                    <h1 class="text-uppercase mt-5 text-primary pt-lg-5 text-center">Indulj a napi nyereményekért<br>és a fődíjért!</h1>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <p class="text-black fw-bold fs-18 mt-4 mb-0 text-center d-block d-lg-none">A promóció időtartama:</p>
                                    <p class="hero__date-wrap fs-18 text-primary fw-bold mb-0 d-block d-lg-none text-center" >2024. július 25. – augusztus 21.</p>
                                    <img src="@/assets/imgs/winnings-fodij.png" class="img-fluid mx-auto d-block"/>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <img src="@/assets/imgs/winnings-heti.png" class="img-fluid mx-auto d-block"/>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <img src="@/assets/imgs/winnings-napi.png" class="img-fluid mx-auto d-block"/>
                                </div>
                                <div class="col-12 py-5 my-5">
                                    <div class="text-center">
                                        <p class="text-black fw-bold fs-24 mb-3 mb-lg-0 d-none d-lg-block">A promóció időtartama:</p>
                                        <p class="hero__date-wrap fs-24 text-primary fw-bold mb-0 d-none d-lg-block" >2024. július 25. – augusztus 21.</p>
                                        <div class="text-center text-dark mt-4">
                                            <button v-if="$store.state.promoStatus != 'before'"
                                                class="btn btn-primary mb-3 order-md-2 me-md-2 md-btn"
                                                v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                                                @click="setGtm('Játszom', 'jatszom')"
                                            >
                                                Játszom!
                                            </button>
                                            <a class="btn btn-primary--outline me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat', 'jatekszabalyzat')">
                                                Játékszabályzat
                                            </a>   
                                        </div>                                      
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>  
                </div>  
            </div>      
            <div class="bg-primary">
                <div class="col-12 col-lg-8 mx-auto text-center fs-14 fs-md-18 text-white py-5 d-flex flex-wrap justify-content-center align-items-center">                                
                    <p class="mb-0 mx-3">A vásárlást igazoló eredeti bizonylatot sértetlen, ép állapotban <b>2024. szeptember 21-ig</b> meg kell őrizni.<br>A képek csak illusztrációk.</p>
                </div>    
            </div>
            <div class="bg-white py-3">
                <div class="d-flex justify-content-center align-items-center hero-logo" style="gap: 40px;overflow-y:auto;">
                    <img src="imgs/products/purina-felix-logo.png" class="img-fluid" width="100"/>
                    <img src="imgs/products/purina-g-logo.png" class="img-fluid" width="100"/>
                    <img src="imgs/products/purina-friskies-logo.png" class="img-fluid" width="100"/>
                </div>
            </div>  
        </div>
    </div>            
</template>

<script>

//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    methods: {      
        setGtm(btnName, eventName){
            this.GTtrackGA4({
                'event' : eventName,
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'button': 'gomb_kattintas'
            })
        }
    }
}
</script>