<template>
    <div id="jatekszabaly" class="rules position-relative block--big">
        <div class="container-xl">

            <div class="row px-2">
                <h2 class="text-primary text-center mb-6 mb-md-7 pb-md-6">
                    A játék lépései
                </h2>
                <div class="col-12  col-lg-10 offset-lg-1 bg-white rules-container">
                    <!-- desctop -->
                    <ul 
                        class="rules-buttons nav nav-pills justify-content-center d-none d-sm-flex w-100 pt-4" 
                        id="pills-tab" 
                        role="tablist">
                        <li 
                            v-for="(tab, tabind) in tabs"
                            :key="'tab-button-' + tab.id" 
                            role="presentation"
                            class="d-flex justify-content-center align-items-start">
                            <div 
                                class="rules-tab-button d-flex align-items-center" 
                                :class="tab.id == 1 ? 'active' : ''"
                                @click="setActiveTab(tab.id)"
                                data-bs-toggle="pill"
                                :data-bs-target="'#pills-' + tab.id"
                                role="tab"
                                aria-selected="true"
                            >
                                <div class="rules-tab-button-content d-flex flex-column">
                                    <div class="d-flex justify-content-center">
                                        <div class="rules-img text-center">
                                            <!-- tab.imgActive -->
                                            <img 
                                                 
                                                :src="tab.id == activeTab ? tab.img : tab.img"
                                                class="img-fluid" 
                                                alt="szabályok"
                                                style="cursor:pointer;"
                                                :style="tab.id == activeTab ? 'opacity:1;' : 'opacity:0.4;'"
                                            >
                                            <div class="rule-main-step-img" :class="`rule-main-step-img--${tab.id}`"></div>
                                            <div class="text-center fw-700 fs-22 text-primary mt-4" :style="tab.id == activeTab ? '' : 'opacity:0.4'">{{tab.name}}</div>
                                        </div>                                        
                                    </div>                                   
                                </div>
                                
                            </div>
                            <img v-if="tabind < tabs.length - 1" src="@/assets/imgs/step-sep.svg" class="mx-4" style="margin-top:30px"/>
                        </li>
                    </ul>
                    <!-- desctop -->
                    <div 
                        class="tab-content flex-1 d-none d-sm-block mt-5" 
                        id="pills-tabContent">                        
                            <div
                                v-for="tab in tabs"
                                :key="'tab-content-' + tab.id" 
                                class="tab-pane fade"
                                :class="tab.id == 1 ? 'show active' : ''" 
                                :id="'pills-' + tab.id" 
                                role="tabpanel" 
                                aria-labelledby="'pills-'+ tab.id + '-tab'"
                            >
                                <div 
                                    class="rules-tab-content d-flex align-items-center"
                                    :class="content[2] == '1' || content[2] == '3' || content[2] == '4' || content[2] == '6' || content[2] == '9' ? 'add-border w-100':''"
                                    v-for="(content, i) in tab.content" :key="i"
                                >  
                                    <p class="font-main m-0 text-primary me-3 fw-700 pb-4 fs-30 fs-md-60">
                                        <!-- <img :src="'imgs/'+content[0]" width="45" height="45"> -->
                                        {{content[2]}}
                                    </p>
                                    <p class="text-black py-3 m-0" 
                                        v-html="content[1]"
                                        ></p>
                                </div>
                        </div>
                    </div>

                    <!-- MOBILE -->
                    <div id="gameRuleCarousel" class="carousel slide d-block d-sm-none" data-bs-ride="carousel">                        
                        <div class="carousel-inner" >
                            <div class="carousel-item" data-bs-interval="8000"
                                :class="tab.id == 1 ? 'active' : ''"
                                v-for="tab in tabs"
                                :key="tab.id" >
                                <div class="rules-img rules-img--mobile text-center">
                                    <img 
                                        :src="tab.img" 
                                        class="img-fluid" 
                                        :alt="tab.name"
                                    >
                                    <div class="rule-main-step-img" :class="`rule-main-step-img--${tab.id}`"></div>
                                    <div class="text-center fw-700 fs-22 text-primary mt-4 mb-4">{{tab.name}}</div>
                                </div>
                                
                                <div class="rules-tab-content d-flex align-items-center justify-content-center font-secondary mb-4"
                                        v-for="(content, i) in tab.content" :key="i">
                                    <div class="d-flex justify-content-center flex-column align-items-center align-items-md-start"
                                        :class="content[2] == '1' || content[2] == '3' || content[2] == '4' || content[2] == '6' || content[2] == '9' ? 'add-border w-100':''"
                                    >  
                                        <p class="font-main m-0 text-primary me-3 pb-4 fw-700 fs-30 fs-md-60">
                                            <!-- <img :src="'imgs/'+content[0]" width="40" height="40"> -->
                                            {{content[2]}}
                                        </p>
                                        <p class="text-black pb-4 m-0" 
                                            v-html="content[1]" 
                                            
                                        ></p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#gameRuleCarousel" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#gameRuleCarousel" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>         

                <!-- BUTTONS      -->
                <div class="col-12 flex-wrap flex-md-nowrap justify-content-center d-flex py-7">
                    <button
                        class="btn btn-primary mb-4 mb-md-0 me-0 me-md-4"
                        v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                        @click="setGtm('Játszom', 'jatszom')"
                    >
                        Játszom
                    </button>
                    <a class="btn btn-primary--outline " :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat', 'jatekszabalyzat')">
                        Játékszabályzat
                    </a> 
                </div>  
                <div class="col-12 text-center">
                    <div class="text-primary good-luck fw-700 fs-24">Jó játékot, sok szerencsét kívánunk!</div>
                </div>
                
            </div> <!-- end of ROW -->
        </div>        
    </div>
</template>

<script>
export default {
    data () {
        return {
            activeTab: 1,
             tabs: [ 
                {
                    id: 1,
                    name: "Vásárolj!",
                    img: "imgs/vasarolj.svg",
                    imgActive: "imgs/vasarolj-active.svg",
                    content:[
                        ["step-1.svg",`Vásárolj egyszerre <span class='fw-bold'>legalább 2000 forint értékben</span> a promócióban részt vevő <a href="#termekek" style="font-weight:bold;text-decoration:none;">FELIX, GOURMET vagy FRISKIES macskaeledel terméket</a> az <b>Auchan áruházakban,</b> vagy az <b>Auchan Online Áruházban </b>(a <a href="https://online.auchan.hu/shop" target="_blank">https://online.auchan.hu/shop</a> oldalon keresztül), hogy részt vehess a játékban.`, "1"],
                        ["step-2.svg",`A nyereményjáték időtartama: <span class='fw-bold d-block d-lg-inline-block'><b>2024. július 25. – augusztus 21.</b></span>`, "2"]
                    ]
                   

                },
                {
                    id: 2,
                    name: "Regisztrálj!",
                    img: "imgs/regisztralj.svg",
                    imgActive: "imgs/regisztralj-active.svg",
                    content: [
                        [
                            "step-3.svg","Regisztrálj, majd töltsd fel a vásárlást igazoló bizonylat adatait és játssz a nyereményekért!", "3"
                        ],
                        [
                            "step-4.svg","A <strong>vásárlást igazoló eredeti bizonylat</strong> sértetlen, ép állapotban történő megőrzése <span style='white-space:nowrap;font-weight:700;'>2024. szeptember 21-ig szükséges</span>.", "4"
                        ],
                        [
                            "step-5.svg","Ne feledd, minél <strong>többször vásárolsz</strong> a promócióban részt vevő termékekből egyidejűleg legalább 2000 Ft értékben, annyiszor <strong>több esélyed van a nyerésre!</strong>", "5"
                        ],                        
                    ]
                    
                },
                {
                    id: 3,
                    name: "Nyerd meg!",
                    img: "imgs/nyerj.svg",
                    imgActive: "imgs/nyerj-active.svg",
                    content: [
                       
                        [
                            "step-6.svg","A játék ideje alatt előre meghatározott <strong>nyerőidőpontokban</strong> (08:00-22:00 között) megnyerhető <strong> naponta 1 db 20.000 Ft értékű PURINA macskaeledel csomag, valamint naponta 5 db Vileda Pet Pro elektrosztatikus kefe.</strong>", "6"
                        ],
                        [
                            "step-7.svg","A fődíj <strong> 5 db Vileda Steam Plus gőztisztító</strong> A játék lezárását követően a <strong>fődíjak sorsolása: 2024. augusztus 27. 14 óra.</strong>", "7"
                        ]
                    ]
                    
                }               
            ]
        }
    },
    methods:{
        setGtm(btnName, eventName){
            this.GTtrackGA4({
                'event' : eventName,
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'button': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
            })
        },
        setActiveTab(index){
            this.activeTab = index;
        }
    }
}
</script>